.footer {
  &__container {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
    border-bottom: 0.0625rem solid #4f4f4f;

    @include media-up(medium-up) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__title {
    font-family: var(--title-font);
    font-size: 3.75rem;
    letter-spacing: -0.04em;
    line-height: 1.25;
    margin-bottom: 2.875rem;

    @include media-down(medium-down) {
      text-align: center;
    }
  }

  &__text {
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 1.875rem;
    max-width: 20rem;

    @include media-down(medium-down) {
      text-align: center;
      margin-bottom: 1.875rem;
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
    }

    @include media-up(large-up) {
      max-width: 40rem;
    }

    @include media-up(x-large-up) {
      max-width: 47.75rem;
    }
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-up(medium-up) {
      justify-content: flex-end;
    }
  }

  &__item {
    &:not(:last-child) {
      margin-right: 1.625rem;
    }

    a {
      font-size: 1.375rem;
    }
  }

  &__copy {
    padding-top: 1.1875rem;
    padding-bottom: 1.1875rem;

    @include media-up(medium-up) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    p {
      font-size: 1rem;
      font-weight: 300;
      letter-spacing: -0.03em;
      line-height: 2.3125rem;

      @include media-down(medium-down) {
        text-align: center;
      }
    }

    a {
      display: flex;
      align-items: center;
      font-size: 1rem;
      font-weight: 300;
      letter-spacing: -0.03em;
      line-height: 1.25rem;

      img {
        width: 100%;
        max-width: 1.625rem;
        margin-left: 0.3125rem;
      }

      @include media-down(medium-down) {
        justify-content: center;
      }
    }
  }
}

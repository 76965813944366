.hero {
  color: var(--white);
  position: relative;
  min-height: 47rem;
  height: 100vh;
  background: url('./images/hero-bg.png') no-repeat center center;
  background-size: cover;
  display: flex;
  align-items: center;
  overflow: hidden;

  &--enviado {
    &::before {
      content: '';
      display: block;
      width: 100% !important;
      height: 100%;
      background: rgba(0, 0, 0, 0.5) !important;
      right: 0 !important;
      left: 0;
    }
  }

  &::before {
    content: '';
    display: block;
    width: 45rem;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.5) 33.45%,
      rgba(255, 255, 255, 0) 100%
    );
    position: absolute;
    top: 0;

    @media (min-width: 48em) {
      right: 50%;
    }

    @media (min-width: 62em) {
      width: 60rem;
    }

    @media (min-width: 120em) {
      width: 100rem;
    }
  }

  &__title {
    font-size: 3rem;
    letter-spacing: -0.06em;
    line-height: 1.125;
    text-transform: uppercase;
    max-width: 31.5rem;
    margin-bottom: 3rem;

    @include media-up(small-up) {
      font-size: 4.0625rem;
    }

    &--enviado {
      text-align: center;
      max-width: 100%;
    }
  }

  &__nav {
    display: grid;
    gap: 1.875rem;
    max-width: 32.5rem;

    @include media-up(small-up) {
      grid-template-columns: 1fr 1fr;
    }

    &--enviado {
      max-width: 100%;
      @include media-up(small-up) {
        grid-template-columns: 100% !important;
      }
    }
  }

  &__link {
    display: block;
    color: var(--white);
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.375rem;
    text-align: center;
    text-transform: uppercase;
    border: 0.125rem solid var(--white);
    padding: 1rem 0.5rem 0.9375rem;

    &--white {
      background: var(--white);
      color: var(--black);
    }

    &--enviado {
      margin: 0 auto;
      width: 100%;
      max-width: 15.3125rem;
    }
  }

  &__social {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    transform: translateX(-50%);

    @include media-down(small-down) {
      bottom: 3.75rem;
    }

    @include media-up(small-up) {
      width: calc(((100vw - var(--container-sm)) / 2));
      top: 50%;
      left: 0;
      flex-direction: column;
    }

    @include media-up(medium-up) {
      width: calc(((100vw - var(--container-md)) / 2));
    }

    @include media-up(large-up) {
      width: calc(((100vw - var(--container-lg)) / 2));
    }

    @include media-up(x-large-up) {
      width: calc(((100vw - var(--container-xl)) / 2));
    }

    @include media-up(xx-large-up) {
      width: calc(((100vw - var(--container-xxl)) / 2));
    }
  }

  &__item {
    &:not(:last-child) {
      @include media-down(small-down) {
        margin-right: 1.375rem;
      }

      @include media-up(small-up) {
        margin-bottom: 1.375rem;
      }
    }

    a {
      color: var(--white);
      font-size: 1.525rem;
    }
  }
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 600;
  color: var(--white);
  padding-top: 0.9375rem;

  &__bg {
    width: 100%;
    position: absolute;
    background: linear-gradient(180deg, #000000 -81.25%, rgba(0, 0, 0, 0) 100%);
    top: 0;
    left: 0;
    height: 8rem;
    transform: translateY(-1.25rem);
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    background: none;
    appearance: none;
    border: none;
    padding: 0;
    cursor: pointer;
    z-index: 10000;
    position: relative;

    @include media-up(small-up) {
      margin-top: 0.625rem;
    }

    &:focus {
      outline: none;
    }

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 0.1875rem;
      background: var(--white);
      box-shadow: 0 0.375rem var(--white), 0 -0.375rem var(--white);
      transition: all 0.3s;
    }

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 0.1875rem;
      background: var(--white);
      position: absolute;
      transition: all 0.3s;
    }

    &.active {
      &::before {
        transform: rotate(45deg);
        box-shadow: none;
      }

      &::after {
        transform: rotate(-45deg);
      }
    }

    @include media-up(large-up) {
      display: none;
    }
  }

  &__nav {
    @include media-down(large-down) {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background: rgba(51, 51, 51, 0.4);
      backdrop-filter: blur(30px);
      z-index: 999;
      overflow: auto;
      padding: 1.875rem;
      align-items: center;
      flex-direction: column;
    }

    &.active {
      @include media-down(large-down) {
        display: flex;
        opacity: 0;
        animation: showMenu 0.5s forwards;
      }
    }

    .footer__list {
      margin-top: 1.875rem !important;
      @include media-up(large-up) {
        display: none;
      }

      a {
        color: var(--white) !important;
      }
    }
  }

  &__menu {
    display: flex;

    @include media-down(large-down) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 6rem;
    }

    &__contato {
      display: block;
      color: var(--black);
      font-size: 1.125rem;
      font-weight: 600;
      line-height: 1.375rem;
      text-align: center;
      text-transform: uppercase;
      border: 0.125rem solid var(--white);
      padding: 1rem 0.5rem 0.9375rem;
      background: var(--white);
      font-weight: 300;

      @include media-up(large-up) {
        display: none;
      }
    }
  }

  &__item {
    @include media-down(large-down) {
      margin-bottom: 1.875rem;
    }

    &:not(:last-child) {
      @include media-up(large-up) {
        margin-right: 1.875rem;
      }
    }
  }

  &__link {
    color: var(--white);
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.375rem;

    @include media-down(large-down) {
      font-size: 1.5rem;
    }
  }

  &__logo {
    font-family: var(--title-font);
    font-size: 2.5rem;
    letter-spacing: -0.04em;
    line-height: 3.125rem;
    transform: translateX(-50%);
    position: absolute;
    left: 50%;
    top: 0.0000625rem;
    z-index: 1001;

    @include media-up(small-up) {
      transform: translateX(-50%);
      position: absolute;
      left: 50%;
      top: 0.0000625rem;
    }
  }

  &__contato {
    display: block;
    color: var(--black);
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.375rem;
    text-align: center;
    text-transform: uppercase;
    border: 0.125rem solid var(--white);
    padding: 0.875rem 1rem 0.8125rem;
    background: var(--white);
    font-weight: 300;

    @include media-down(large-down) {
      display: none;
    }
  }

  &__social {
    display: flex;
    align-items: center;

    @include media-down(large-down) {
      display: none;
    }

    ul {
      margin-right: 1.875rem;
      a {
        color: var(--white);
      }
    }
  }
}

@keyframes showMenu {
  to {
    opacity: 1;
  }
}

.espaco {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
  background: var(--white-2);
  text-align: center;

  &__title {
    font-family: var(--title-font);
    font-size: 3.75rem;
    letter-spacing: -0.04em;
    line-height: 1.25;
    margin-bottom: 2.75rem;
  }

  &__text {
    max-width: 71.5rem;
    font-weight: 300;
    font-size: 1.125rem;
    line-height: 1.875rem;
    margin: 0 auto 2.5rem;
  }

  &__link {
    display: block;
    color: var(--white);
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 1.375rem;
    text-align: center;
    text-transform: uppercase;
    border: 0.125rem solid var(--black);
    background: var(--black);
    padding: 1rem 0.5rem 0.9375rem;
    max-width: 15.3125rem;
    margin-left: auto;
    margin-right: auto;
  }

  &__gallery {
    max-width: 80.75rem;
    margin: 0 auto;
    display: grid;
    margin-bottom: 3.75rem;

    @include media-down(small-down) {
      gap: 1.875rem;
    }

    @include media-up(small-up) {
      grid-template-columns: 1fr 1fr;
    }

    &__item {
      position: relative;
      padding-bottom: 121.450617284%;
      overflow: hidden;

      &:hover {
        img {
          transform: scale(1.4);
        }
      }
    }

    &__image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      transition: transform 0.5s;
    }
  }
}

.sobre {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
  text-align: center;
  position: relative;

  @include media-down(medium-down) {
    display: grid;
    gap: 3.75rem;
  }

  @include media-up(medium-up) {
    padding-bottom: 6.875rem;
    text-align: right;
  }

  &__title {
    font-family: var(--title-font);
    font-size: 3.75rem;
    letter-spacing: -0.04em;
    line-height: 1.25;
    margin-bottom: 1rem;
  }

  &__text {
    max-width: 35.75rem;
    margin-left: auto;
    margin-bottom: 2.75rem;
    font-weight: 300;
    font-size: 1.125rem;
    line-height: 1.875rem;
  }

  &__link {
    display: block;
    color: var(--white);
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 1.375rem;
    text-align: center;
    text-transform: uppercase;
    border: 0.125rem solid var(--black);
    background: var(--black);
    padding: 1rem 0.5rem 0.9375rem;
    max-width: 15.3125rem;
    margin-left: auto;

    @include media-down(medium-down) {
      margin-right: auto;
    }
  }

  &__gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.9375rem;

    @include media-down(medium-down) {
      grid-row: 2;
    }

    @include media-up(small-up) {
      gap: 1.875rem;
    }

    @include media-up(medium-up) {
      position: absolute;
      top: 50%;
      right: calc(50% + 14rem);
      width: 50rem;
      transform: translateY(-50%);
    }

    @include media-up(large-up) {
      width: 62rem;
      right: calc(50% + 7.5rem);
    }

    @include media-up(x-large-up) {
      right: calc(50% + 1.875rem);
    }

    @include media-up(xx-large-up) {
      width: 65.875rem;
      right: calc(50% - 5.875rem);
    }

    &__item {
      position: relative;
      padding-bottom: 130.303030303%;
    }

    &__image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;

      &:nth-child(2) {
        animation: imageAnimation 4s infinite;
      }
    }
  }
}

@keyframes imageAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.contato {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
  background: var(--white-2);

  &__container {
    display: grid;
    gap: 3.75rem;
    align-items: center;

    @include media-up(large-up) {
      grid-template-columns: repeat(12, 1fr);
      gap: 1.875rem;
    }
  }

  &__form {
    @include media-up(large-up) {
      grid-column: 1 / 6;
    }
  }

  &__title {
    font-family: var(--title-font);
    font-size: 3.75rem;
    letter-spacing: -0.04em;
    line-height: 1.25;
    margin-bottom: 1rem;

    @include media-down(large-down) {
      text-align: center;
    }
  }

  &__text {
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 1.875rem;
    margin-bottom: 2.125rem;
    max-width: 33.125rem;

    @include media-down(large-down) {
      text-align: center;
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__input {
    position: relative;
    max-width: 30.5rem;

    @include media-down(large-down) {
      margin-right: auto;
      margin-left: auto;
    }

    input,
    select,
    textarea {
      display: block;
      width: 100%;
      background: none;
      appearance: none;
      border: 0.125rem solid var(--grey-2);
      font-size: 1.125rem;
      line-height: 1.4;
      font-weight: 300;
      color: var(--black);
      margin-bottom: 2.25rem;
      padding-right: 1.375rem;
      padding-left: 1.375rem;

      &:focus {
        outline: none;
      }

      &.empty {
        border-color: red;

        & + label {
          color: red;

          & + span {
            opacity: 1;
          }
        }
      }
    }

    span {
      content: 'O campo não pode estar vazio';
      display: block;
      color: red;
      font-size: 0.875rem;
      position: absolute;
      bottom: 0;
      transform: translateY(calc(100% + 0.25rem));
      opacity: 0;
    }

    input,
    select {
      height: 3.5rem;
    }

    textarea {
      height: 10.5rem;
      resize: none;
      padding-top: 0.75rem;
    }

    label {
      position: absolute;
      top: 0;
      left: 0.875rem;
      color: var(--grey-3);
      transform: translateY(-50%);
      padding: 0 0.625rem;
      background: var(--white-2);
    }
  }

  &__button {
    display: block;
    color: var(--white);
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 1.375rem;
    text-align: center;
    text-transform: uppercase;
    border: 0.125rem solid var(--black);
    background: var(--black);
    padding: 1rem 0.5rem 0.9375rem;
    max-width: 30.5rem;
    width: 100%;
    appearance: none;

    @include media-down(large-down) {
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__content {
    @include media-up(large-up) {
      grid-column: 6 / 13;
    }
  }

  &__list {
    max-width: 46.875rem;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 2.5rem;
    margin-bottom: 5rem;
    column-gap: 0.9375rem;

    @include media-up(small-up) {
      grid-template-columns: repeat(3, 1fr);
    }

    p {
      max-width: 13.125rem;
      text-align: center;
      margin: 0 auto;
      font-size: 1.125rem;
      line-height: 1.875rem;
      font-weight: 300;
    }
  }

  &__image {
    width: 4.625rem;
    height: 4.625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 0.9375rem;
  }

  &__map {
    position: relative;
    width: 100%;
    padding-bottom: 43.363844%;

    &__inner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__infos {
    margin-top: 3.75rem;
    text-align: right;

    &__item {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 1.25rem;

      &:not(:last-child) {
        margin-bottom: 1.875rem;
      }

      p {
        font-size: 1.125rem;
        line-height: 1.875rem;
        font-weight: 300;
        letter-spacing: 0.02em;
        margin-right: 0.625rem;
      }
    }
  }
}

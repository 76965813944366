:root {
  /* Colors */
  --black: #111;
  --white: #fff;
  --white-2: #f7f7f7;
  --grey: #333;
  --grey-2: #bdbdbd;
  --grey-3: #828282;

  /* Typography */
  --main-font: 'Quicksand', sans-serif;
  --title-font: 'Great Vibes', cursive;

  /* Units */
  --container-xxl: 95em; // 1520px
  --container-xl: 71.25rem; // 1140px
  --container-lg: 60rem; // 960px
  --container-md: 45rem; // 720px
  --container-sm: 33.75rem; // 540px
  --gutter: 0.9375rem; // 15px
}

html {
  box-sizing: border-box;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

body {
  color: var(--black);
  font-family: var(--main-font);
  font-size: 1rem;
}

a {
  color: var(--black);
  text-decoration: none;
}

img {
  display: block;
  max-width: 100%;
}

button {
  cursor: pointer;
  display: block;
}

.container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 100%;
  padding-left: calc(var(--gutter) * 2);
  padding-right: calc(var(--gutter) * 2);

  @include media-up(small-up) {
    padding-left: 0;
    padding-right: 0;
    max-width: var(--container-sm);
  }

  @include media-up(medium-up) {
    max-width: var(--container-md);
  }

  @include media-up(large-up) {
    max-width: var(--container-lg);
  }

  @include media-up(x-large-up) {
    max-width: var(--container-xl);
  }

  @include media-up(xx-large-up) {
    max-width: var(--container-xxl);
  }
}

.no-overflow {
  overflow: hidden;
}

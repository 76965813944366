.eventos {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
  background: var(--grey);
  position: relative;
  color: var(--white);
  text-align: center;

  &__title {
    font-family: var(--title-font);
    font-size: 3.75rem;
    letter-spacing: -0.04em;
    line-height: 1.25;
    margin-bottom: 1rem;
  }

  &__text {
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 1.875rem;
    max-width: 57.25rem;
    margin: 0 auto;
  }

  &__slide {
    margin-top: 3.5rem;

    &__item {
      width: 260px;

      @include media-up(small-up) {
        width: 318px;
      }

      &--width {
        height: 0.0625rem;
        width: calc(1.875rem - 1.875rem);

        @include media-up(small-up) {
          width: calc(((100vw - var(--container-sm)) / 2) - 1.875rem);
        }

        @include media-up(medium-up) {
          width: calc(((100vw - var(--container-md)) / 2) - 1.875rem);
        }

        @include media-up(large-up) {
          width: calc(((100vw - var(--container-lg)) / 2) - 1.875rem);
        }

        @include media-up(x-large-up) {
          width: calc(((100vw - var(--container-xl)) / 2) - 1.875rem);
        }

        @include media-up(xx-large-up) {
          width: calc(((100vw - var(--container-xxl)) / 2) - 1.875rem);
        }
      }
    }

    &__image {
      position: relative;
      width: 100%;
      padding-bottom: 174.528301887%;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &__text {
      color: var(--white);
      font-family: var(--title-font);
      font-size: 3.75rem;
      letter-spacing: -0.04em;
      line-height: 1.25;
      transform: translateY(-50%);
    }

    .owl-nav {
      position: relative !important;
      z-index: 900;
      display: flex;
      padding-left: 1.875rem;
      @include media-up(small-up) {
        padding-left: calc(((100vw - var(--container-sm)) / 2));
      }

      @include media-up(medium-up) {
        padding-left: calc(((100vw - var(--container-md)) / 2));
      }

      @include media-up(large-up) {
        padding-left: calc(((100vw - var(--container-lg)) / 2));
      }

      @include media-up(x-large-up) {
        padding-left: calc(((100vw - var(--container-xl)) / 2));
      }

      @include media-up(xx-large-up) {
        padding-left: calc(((100vw - var(--container-xxl)) / 2));
      }

      button {
        background: #c4c4c4 !important;
        color: var(--black) !important;
        padding: 0.5625rem 1.25rem 0.5rem 1.3125rem !important;
        border-radius: 2.75rem;
        font-size: 1rem !important;

        &:focus {
          outline: none;
        }

        &:nth-child(1) {
          margin-right: 1.25rem;
        }
      }
    }
  }

  &__bg {
    position: absolute;
    bottom: 0;
    width: 15%;
    height: 807px;
    pointer-events: none;
    z-index: 800;

    &--right {
      right: 0;
      background: linear-gradient(
          270deg,
          #333333 10.16%,
          rgba(51, 51, 51, 0) 67.29%
        ),
        linear-gradient(270deg, #333333 10.16%, rgba(51, 51, 51, 0) 67.29%);
    }

    &--left {
      left: 0;
      background: linear-gradient(
          270deg,
          #333333 10.16%,
          rgba(51, 51, 51, 0) 67.29%
        ),
        linear-gradient(270deg, #333333 10.16%, rgba(51, 51, 51, 0) 67.29%);
      transform: matrix(-1, 0, 0, 1, 0, 0);
    }
  }
}
